import React from 'react'
import { GatsbySeo } from 'gatsby-plugin-next-seo'
import { graphql } from 'gatsby'
import Layout from '../components/UI/layout/Layout'
import { GridContent } from '../components/UI/layout/grid-content'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'
import { BgImage } from 'gbimage-bridge'
import MaxiMenu from '../components/UI/navigation/maxi-menu'
import { Link } from 'gatsby'
import {
  AboveTheFold,
  FilmAboveTheFold,
} from '../components/UI/layout/landing-page'
import Carousel from '../components/UI/carousel/carousel'

const FilmLocationPage = ({ data }) => {
  const { introduction, page_1 } =
    data.allFile.edges[0].node.childMarkdownRemark.frontmatter

  const film = data.allMarkdownRemark.edges.filter(
    edge => edge.node.frontmatter.cms === 'film'
  )

  return (
    <Layout>
      <GatsbySeo title="Film Location" />
      <AboveTheFold files={introduction.videos} />
      <GridContent
        id="film-page-introduction"
        mode="white-mode"
        layout="--2-column"
      >
        <div className="text-content-margin">
          <h2>{introduction.title}</h2>
          <h3>{introduction.sub_heading}</h3>
          <br />
          <br />
          <p>{introduction.body}</p>
        </div>

        <BgImage
          image={getImage(introduction.image)}
          className="image-background float-right"
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'left center',
          }}
        />
      </GridContent>
      <GridContent id="film-page-1" layout="--2-column" mode="film-location">
        <BgImage
          className="image-background float-left"
          image={getImage(page_1.image)}
          style={{
            backgroundSize: 'cover',
            backgroundPosition: 'right center',
          }}
        />
        <p className="text-content-margin">{page_1.body}</p>
      </GridContent>
      <GridContent
        id="film-page-2"
        layout="--1-column content-margin-top content-margin-bottom"
        mode="white-mode"
      >
        <h3>Previous productions at Ballywalter Park</h3>
        <br />
        <Carousel>
          {film.map((production, i) => {
            return (
              <li
                className="slide"
                id={`carousel__slide-${i}`}
                tabIndex="0"
                key={i}
              >
                <div className="subject">
                  <GatsbyImage
                    image={getImage(production.node.frontmatter.image)}
                    alt={production.node.frontmatter.title}
                    style={{ minWidth: '50%' }}
                  />
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      margin: '12px 24px',
                    }}
                  >
                    <h1>{production.node.frontmatter.title}</h1>
                    <div
                      className="markdown"
                      dangerouslySetInnerHTML={{
                        __html: production.node.html,
                      }}
                    />
                    <Link
                      to={production.node.fields.slug}
                      className="mock-button"
                      style={{ marginTop: 'auto', alignSelf: 'flex-end' }}
                    >
                      Learn More
                    </Link>
                  </div>
                </div>
              </li>
            )
          })}
        </Carousel>
      </GridContent>

      <MaxiMenu />
    </Layout>
  )
}

export default FilmLocationPage

export const query = graphql`
  query {
    allFile(filter: { name: { eq: "film" } }) {
      edges {
        node {
          childMarkdownRemark {
            frontmatter {
              introduction {
                body
                title
                sub_heading
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 820
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
                videos {
                  webm {
                    publicURL
                  }
                  mp {
                    publicURL
                  }
                }
              }
              page_1 {
                body
                image {
                  childImageSharp {
                    gatsbyImageData(
                      width: 820
                      placeholder: BLURRED
                      formats: [AUTO, WEBP, AVIF]
                    )
                  }
                }
              }
            }
          }
        }
      }
    }

    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          id
          html
          fields {
            slug
          }
          frontmatter {
            date
            title
            cms
            image {
              childImageSharp {
                gatsbyImageData(
                  width: 820
                  placeholder: BLURRED
                  formats: [AUTO, WEBP, AVIF]
                )
              }
            }
          }
        }
      }
    }
  }
`
